.imageupload-root {
  margin: 10px 0;
  padding: 10;
  display: flex;
  flex-direction: column;

  &__fileinput {
    padding: 10;
  }

  button {
    margin: 0;
  }

  &__btn {
    padding: 10px;
    margin-top: 10px;
  }
}
