.styledtable-root {
  background-color: white;

  &__head {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: lightgreen;
    font-weight: bold;
  }

  &__sort,
  &__filter {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;

    color: black;
    font-weight: bold;
    div {
      margin-right: 16px;
    }

    span {
      margin-right: 20px;
    }

    input {
      margin-right: 12px;
    }
  }

  &__tholder {
    width: calc(100vw - 260px);
    max-width: calc(100vw - 260px);
    overflow-x: scroll;
  }

  &__table {
    width: 100%;
    border-top: 2px solid lightgreen;

    & thead {
      font-weight: bold;
      color: '#2b3648';
    }

    & tr {
      & td {
        padding: 8px 16px;

        & .even {
          background-color: #f1f2f7;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      &__btn {
        margin-right: 10px;
        background-color: transparent;
        border-radius: 3px;
        box-shadow: none;
        border: 1px solid grey;

        &:hover {
          background-color: lightgray;
        }
      }
    }

    & tfoot {
      & button {
        background-color: white;
        margin-right: 20px;
        border: 1px solid grey;
        box-shadow: none;
      }
    }
  }
}
