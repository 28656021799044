.snackbar-root {
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 6px;
  padding: 20px;
  width: 260px;
  background-color: #6c757d;
  color: white;
  transition: all 0.5s;
  visibility: hidden;

  &.error {
    background-color: #dc3545;
  }

  &.success {
    background-color: #28a745;
  }

  &.info {
    background-color: #17a2b8;
  }

  &.warning {
    background-color: #ffc107;
  }

  &.showing {
    visibility: visible;
    transform: translateY(0);
  }

  &.hidden {
    visibility: hidden;
    transform: translateY(-200px);
  }
}
