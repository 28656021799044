.loader-modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9980;
  align-items: "center";
  justify-content: "center";

  &__loadingbox {
    padding: 30;
    background-color: white;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
    border-radius: 3;
  }
}
