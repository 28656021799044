.companyDetail-root {
  display: flex;
  flex-direction: column;

  &__header {
    padding: 10px 20px;
    text-align: right;
  }

  &__map {
    position: relative;
    width: 612px;
    height: 700px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
    border: 2px solid black;

    &__pin {
      position: absolute;
      height: 32px;
      width: 22px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top left;
      top: 0;
      left: 0;
      z-index: 99;

      &__title {
        position: absolute;
        display: none;
        background-color: white;
        padding: 2px;
        font-weight: bold;
        bottom: -12px;
        left: 0;
        z-index: 90;
      }

      &:hover {
        cursor: pointer;
      }

      &:hover &__title {
        display: inline-block;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      margin-top: 40px;
    }

    &__imgbox {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
    }

    &__grouped {
      display: flex;
      flex-direction: column;
    }

    &__codes {
      display: flex;
      flex-direction: column;

      & input,
      & textarea {
        margin: 16px 0;
      }

      & button {
        padding: 20px;
      }
    }

    &__table {
      background-color: white;
      border: 1px solid black;

      & td {
        padding: 8px;
        vertical-align: middle;
        border-bottom: 1px solid lightgray;
      }
    }
  }
}
