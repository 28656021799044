.blogdetail-root {
  display: flex;
  flex-direction: column;

  &__header {
    padding: 10px 20px;
    text-align: right;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      margin-top: 40px;
    }

    &__imgbox {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
    }

    &__grouped {
      display: flex;
      flex-direction: column;
    }

    &__codes {
      display: flex;
      flex-direction: column;

      & input,
      & textarea {
        margin: 16px 0;
      }

      & button {
        padding: 20px;
      }
    }

    &__table {
      background-color: white;

      & td {
        padding: 8px;
        vertical-align: middle;
        border-bottom: 1px solid lightgray;
      }
    }
  }
}
