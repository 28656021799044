.layout-root {
  width: 100%;
  min-height: 100%;

  &__main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: grey;

    &__page {
      width: 100%;
      background-color: #f9fafa;
    }
  }
}
