.formblock-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 840px;
  margin-bottom: 30px;

  &__title {
    width: 100%;
    padding-right: 20px;
    color: grey;
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__content {
    background-color: #f1f2f7;
    padding: 40px;
    border-radius: 30px;
    flex: 1;
  }
}
