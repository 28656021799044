.csvuploader-root {
  margin: 10px 0;
  padding: 10;

  &__fileinput {
    padding: 10;
  }

  &__btn {
    padding: 10px;
    margin-top: 10px;
  }

  & p {
    margin-top: 15px;
  }
}
